import React from 'react'
import { config, useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const Description = styled(animated.div)`
  max-width: 960px;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
  padding-top: 0.1em;
`

const Contact = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })

  return (
    <Layout color="#FFFFCF">
      <SEO title="Contact | Kiki" desc="Kiki's Tattoo Service | @ink.by.kiki | Vancouver, BC" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 10]}>
        <animated.h1 style={titleAnimation}>Contact</animated.h1>
        <Description style={descAnimation}>
          <h4>Social Media</h4>
          <p>
            <b>IG: </b><a href="https://www.instagram.com/ink.by.kiki/"> @ink.by.kiki</a>
          </p>
          <p>
            <b>Facebook: </b> @ink.by.kiki
          </p>
          <p>
            <b>Email: </b> kiki@inkbykiki.com
          </p>

        </Description>
      </AnimatedBox>
    </Layout>
  )
}

export default Contact
